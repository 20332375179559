import React, { FC, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Avatar,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Link as LinkMUi,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import clsx from 'clsx';

import assinaturaImg from '../../Assets/interativa.png';
import logoImg from '../../Assets/logo.png';
import menu from '../../config/menuConfig';
import { useAuth } from '../../Hooks/auth';
import { useStyles } from './styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkMUi color="inherit" href="https://coopern.com.br/" target="_blank">
        COOPERN
      </LinkMUi>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const Default: FC = ({ children }) => {
  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION || '-';
  }, []);

  const primaryMenus = menu.getPrimaryMenus();
  const secondMenus = menu.getSecondMenus();
  const { location } = useHistory();
  const menuSelected = useMemo(() => {
    return menu.getSelected(location.pathname);
  }, [location.pathname]);

  const classes = useStyles();
  const { signOut, user } = useAuth();
  const [open, setOpen] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {menuSelected?.title || 'Painel de Controle'}
          </Typography>

          <Button
            aria-describedby={anchorEl ? 'simple-popover' : undefined}
            onClick={handleClick}
          >
            <Avatar className={clsx(classes.avatar)} />
            <Typography className={clsx(classes.profile)}>
              {user.name}
            </Typography>
          </Button>
          <Popover
            id={anchorEl ? 'simple-popover' : undefined}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List className={clsx(classes.listPopover)} onClick={handleClose}>
              <ListItem button component={Link} to="/profile">
                <ListItemText primary="Perfil" />
              </ListItem>
              <Divider />
              <List>
                <ListItem button onClick={handleSignOut}>
                  <ListItemText primary="Sair" />
                </ListItem>
              </List>
            </List>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={logoImg} alt="COOPERN" className={classes.toolbarImage} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {/* Menu */}
        <List>
          {!!primaryMenus.length &&
            primaryMenus.map((itemMenu) => {
              return (
                <ListItem
                  key={itemMenu.url}
                  button
                  component={Link}
                  to={itemMenu.url}
                >
                  <ListItemIcon>
                    <itemMenu.Icon />
                  </ListItemIcon>
                  <ListItemText primary={itemMenu.title} />
                </ListItem>
              );
            })}
        </List>

        <Divider />
        <List>
          <ListItem button onClick={handleSignOut}>
            <ListItemIcon>
              <PowerIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
        <Divider />

        <List>
          {!!secondMenus.length &&
            secondMenus.map((itemMenu) => {
              return (
                <ListItem
                  key={itemMenu.url}
                  button
                  component={Link}
                  to={itemMenu.url}
                >
                  <ListItemIcon>
                    <itemMenu.Icon />
                  </ListItemIcon>
                  <ListItemText primary={itemMenu.title} />
                </ListItem>
              );
            })}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}

          <Box pt={4}>
            <Copyright />
            <Box color="text.secondary" className={clsx(classes.footer)}>
              <Typography variant="body1" className={clsx(classes.description)}>
                Cooperativa de Trabalho e Serviços do Rio Grande do Norte
              </Typography>
              <Typography variant="body2" className={clsx(classes.version)}>
                Versão {version}
              </Typography>

              <LinkMUi
                href="https://www.interativadigital.com.br/"
                title="Interativa Digital - Desde 1998 desenvolvendo os melhores projetos para Internet."
                target="_blank"
              >
                <img
                  src={assinaturaImg}
                  alt="Interativa"
                  style={{ width: '80px' }}
                />
              </LinkMUi>
            </Box>
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default Default;
