import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#19B66E',
      contrastText: '#fff',
    },
    secondary: {
      main: '#089E92',
      contrastText: '#fff',
    },
    error: {
      main: '#F83539',
    },
    /* primary: {
            light: '',
            main: '',
            dark: '',
            contrastText: ''
        },
        secondary: {
            light: '',
            main: '',
            dark: '',
            contrastText: ''
        },
        error: {
            light: '',
            main: '',
            dark: '',
            contrastText: ''
        }, */
    text: {
      primary: '#333333',
      secondary: '#00000099',
    },
  },
  typography: {
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 60,
    },
    h3: {
      fontSize: 48,
    },
    h4: {
      fontSize: 34,
    },
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 20,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
    overline: {
      fontSize: 10,
    },
  },
  overrides: {},
});
