import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import theme from '../Theme';
import { AuthProvider } from './auth';
import { SnackbarCustomProvider } from './snackbar';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <SnackbarCustomProvider>{children}</SnackbarCustomProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  </AuthProvider>
);

export default AppProvider;
