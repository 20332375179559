import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';

type Menu = {
  title: string;
  url: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

const primaryMenu: Menu[] = [
  {
    title: 'Painel de Controle',
    Icon: DashboardIcon,
    url: '/dashboard',
  },
  {
    title: 'Arquivos',
    Icon: DescriptionIcon,
    url: '/files',
  },
  {
    title: 'Usuários',
    Icon: PeopleIcon,
    url: '/users',
  },
  {
    title: 'Perfil',
    Icon: PersonIcon,
    url: '/profile',
  },
  {
    title: 'Mensagem',
    Icon: PersonIcon,
    url: '/birthday-message',
  },
];

const secondMenu: Menu[] = [
  {
    title: 'Suporte',
    Icon: InfoIcon,
    url: '/support',
  },
];

const fullMenu: Menu[] = [...primaryMenu, ...secondMenu];

const menu = {
  getPrimaryMenus: () => {
    return primaryMenu;
  },
  getSecondMenus: () => {
    return secondMenu;
  },
  getSelected: (pathname: string) => {
    return fullMenu.find((item) => pathname.includes(item.url));
  },
};

export default menu;
