import React, { FC } from 'react';

import AppNavigation from '../Routes/AppNavigation';

const RootLayout: FC = () => (
  <>
    <AppNavigation />
  </>
);

export default RootLayout;
