import React, { FC, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import BirthdayMessage from '../Pages/Admin/BirthdayMessage';
import Authentication from '../Templates/Authentication';
import Default from '../Templates/Default';
import Route from './Route';

const Landing = lazy(() => import('../Pages/Admin/Landing'));
const Dashboard = lazy(() => import('../Pages/Admin/Dashboard'));
const Profile = lazy(() => import('../Pages/Admin/Profile'));
const Support = lazy(() => import('../Pages/Admin/Support'));
const User = lazy(() => import('../Pages/Admin/User'));
const ViewUser = lazy(() => import('../Pages/Admin/User/ViewUser'));
const CreateUser = lazy(() => import('../Pages/Admin/User/CreateUser'));
const Files = lazy(() => import('../Pages/Admin/Files'));
const SignIn = lazy(() => import('../Pages/Auth/SignIn'));
const ForgotPassword = lazy(() => import('../Pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../Pages/Auth/ResetPassword'));
const Page404 = lazy(() => import('../Pages/NotFound'));

const Appnavigation: FC = () => {
  return (
    <>
      <Suspense fallback={<LinearProgress color="primary" />}>
        <Switch>
          <Route path="/" exact component={SignIn} template={Authentication} />
          <Route
            path="/forgot-password"
            exact
            component={ForgotPassword}
            template={Authentication}
          />
          <Route
            path="/reset-password/:token"
            exact
            component={ResetPassword}
            template={Authentication}
          />

          <Route
            path="/dashboard"
            exact
            isPrivate
            component={Dashboard}
            template={Default}
          />
          <Route
            path="/profile"
            exact
            isPrivate
            component={Profile}
            template={Default}
          />
          <Route
            path="/users/new"
            exact
            isPrivate
            component={CreateUser}
            template={Default}
          />
          <Route
            path="/users/:userId/:edit?"
            exact
            isPrivate
            component={ViewUser}
            template={Default}
          />
          <Route
            path="/users"
            exact
            isPrivate
            component={User}
            template={Default}
          />
          <Route
            path="/files"
            exact
            isPrivate
            component={Files}
            template={Default}
          />
          <Route
            path="/support"
            exact
            isPrivate
            component={Support}
            template={Default}
          />
          <Route
            path="/birthday-message"
            exact
            isPrivate
            component={BirthdayMessage}
            template={Default}
          />
          <Route
            path="/*"
            isNotFound
            component={Page404}
            template={Authentication}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default Appnavigation;
