import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import clsx from 'clsx';
import * as yup from 'yup';

import InputHook from '../../../Components/InputHook';
import { useCustomSnackbar } from '../../../Hooks/snackbar';
import { useStyles } from './styles';
import { IFormInput } from './types';

const schema = yup.object().shape({
  message: yup.string().required('A mensagem é obrigatória'),
});

const BirthdayMessage: React.FC = () => {
  const classes = useStyles();
  const { addSnackbar } = useCustomSnackbar();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [messages, setMessage] = useState<{
    message: string;
    id: number;
  }>();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors, setValue } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      message: messages?.message || '',
    },
  });

  useEffect(() => {
    async function loadMessage() {
      try {
        const { data } = await axios.get('birthday-message');

        setMessage(data);
        setValue('message', data.message);
      } catch (err: any) {
        addSnackbar({
          variant: 'error',
          title:
            err.response?.data?.error ||
            'Ocorreu um erro ao carregar as informações. Tente novamente',
        });
      }
    }

    loadMessage();
  }, [addSnackbar, setValue]);

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      setLoading(true);

      try {
        const { message } = data;

        const formData = {
          message,
        };

        if (messages?.id) {
          const { data: dataUpdate } = await axios.put(
            `birthday-message/${messages.id}`,
            formData,
          );
          setMessage(dataUpdate);
        } else {
          await axios.post('birthday-message', formData);
        }

        addSnackbar({
          variant: 'success',
          title: 'Suas informações foram atualizadas com sucesso!',
        });

        setLoading(false);
      } catch (err: any) {
        setLoading(false);

        addSnackbar({
          variant: 'error',
          title:
            err.response?.data?.error ||
            'Ocorreu um erro ao atualizar. Tente novamente',
        });
      }
    },
    [addSnackbar, messages],
  );

  return (
    <Box>
      <Typography variant="h6" className={classes.mb}>
        Mensagem de Aniversário
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" flexDirection="column" color="#00000099">
                <InputHook
                  name="message"
                  type="text"
                  label="Mensagem"
                  variant="outlined"
                  control={control}
                  error={errors}
                  style={{ marginBottom: 20, marginTop: 15 }}
                  disabled={!!loading}
                  multiline
                  minRows={4}
                />
              </Box>

              <Box display="flex" flexDirection="column" color="#00000099">
                <Typography variant="caption" style={{ marginTop: 10 }}>
                  * Para que a mensagem mostre o nome do aniversariante
                  corretamente, adicione no texto chaves
                  {' {}'} para ser substituído pelo nome do aniversariante.
                </Typography>
                <Typography variant="caption" style={{ marginTop: 10 }}>
                  Exemplo: Parabéns{' {}'}! Que seu dia seja incrível!
                  <br />
                  Como ficará: Parabéns João! Que seu dia seja incrível!
                </Typography>
              </Box>

              <Divider className={classes.marginTopButton} />

              <Box display="flex" justifyContent="flex-end" mt="auto">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  size="large"
                >
                  {loading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Salvar Mensagem'
                  )}
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BirthdayMessage;
