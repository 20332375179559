/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Controller, Control } from 'react-hook-form';

import {
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { Error, Visibility, VisibilityOff } from '@material-ui/icons';

type Props = TextFieldProps & {
  control: Control<Record<string, any>> | undefined;
  name: string;
  type?: string;
  label?: string;
  error?: any;
  variant: 'outlined' | 'filled' | 'standard';
  style?: React.CSSProperties;
  size?: 'medium' | 'small';
};

const InputHook: React.FC<Props> = ({
  control,
  name,
  type = 'text',
  label,
  error,
  variant,
  style,
  size,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = useMemo(() => type === 'password', [type]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: 15, ...style }}
    >
      <Box display="flex" width="100%" position="relative">
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <TextField
              onChange={onChange}
              value={value}
              type={showPassword ? 'text' : type}
              label={label}
              variant={variant}
              style={{ marginBottom: 5, width: '100%' }}
              error={!!error[name]?.message}
              size={size}
              {...rest}
            />
          )}
        />

        {isPassword && (
          <IconButton
            onClick={() => setShowPassword((state) => !state)}
            style={{
              position: 'absolute',
              top: size === 'small' ? 8 : 14,
              right: size === 'small' ? 8 : 14,
            }}
            size="small"
          >
            {showPassword ? (
              <VisibilityOff style={{ width: 20, height: 20 }} />
            ) : (
              <Visibility style={{ width: 20, height: 20 }} />
            )}
          </IconButton>
        )}
      </Box>
      {name && error[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
};

export default InputHook;
