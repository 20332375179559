import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: '150px',
    marginBottom: 45,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 35px',
    width: '100%',
    maxWidth: '450px',
    minWidth: '350px',
    textAlign: 'center',
    backgroundColor: '#fff',
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    padding: '30px 23px',
    boxShadow: '0px 6px 12px #0000001a',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
  },
  version: {
    fontSize: '15px',
    fontWeight: 600,
  },
}));
