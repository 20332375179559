import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Boot from './Boot';
import AppProvider from './Hooks';
import RootLayout from './Layouts/RootLayout';

const App: React.FC = () => {
  Boot().then(() => {});

  return (
    <AppProvider>
      <BrowserRouter>
        <RootLayout />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
