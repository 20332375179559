import React, { createContext, useCallback, useState, useContext } from 'react';

import axios from 'axios';
import { isBefore } from 'date-fns';

interface IUser {
  id: string;
  code: string;
  name: string;
  cpf: string;
  email?: string;
  phone?: string;
  created_at: string;
  updated_at: string;
  role: {
    id: number;
    name: string;
    slug: string;
  };
}

interface AuthState {
  token: string;
  user: IUser;
}

interface ISignInCredentials {
  cpf: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(updateData: Partial<IUser>): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Coopern:token');
    const expires_token = localStorage.getItem('@Coopern:expires_token');
    const user = localStorage.getItem('@Coopern:user');

    if (!expires_token) {
      return {} as AuthState;
    }

    const unexpiredToken = isBefore(
      new Date(),
      new Date(JSON.parse(expires_token)),
    );

    if (token && user && unexpiredToken) {
      axios.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    if (!unexpiredToken) {
      localStorage.removeItem('@Coopern:token');
      localStorage.removeItem('@Coopern:expires_token');
      localStorage.removeItem('@Coopern:user');
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ cpf, password }) => {
    const response = await axios.post('auth/login?application=painel', {
      cpf,
      password,
    });

    const { access_token: token, expires_in, user } = response.data;

    const datetime = new Date();
    const expires_token = new Date(
      datetime.setSeconds(Number(expires_in) - 10),
    );

    localStorage.setItem('@Coopern:token', token);
    localStorage.setItem(
      '@Coopern:expires_token',
      JSON.stringify(expires_token),
    );
    localStorage.setItem('@Coopern:user', JSON.stringify(user));

    axios.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    axios.post('auth/logout');

    localStorage.removeItem('@Coopern:token');
    localStorage.removeItem('@Coopern:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (updateData: IUser) => {
      const userUpdate = {
        ...data.user,
        ...updateData,
      };

      localStorage.setItem('@Coopern:user', JSON.stringify(userUpdate));

      setData({
        token: data.token,
        user: userUpdate,
      });
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
