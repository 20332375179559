import React, { FC, useMemo } from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';

import assinaturaImg from '../../Assets/interativa.png';
import logoImg from '../../Assets/logo.png';
import { useStyles } from './styles';

const Authentication: FC = ({ children }) => {
  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION || '-';
  }, []);
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root)}>
      <img src={logoImg} alt="Coopern" className={clsx(classes.logo)} />

      <Box className={clsx(classes.content)}>{children}</Box>

      <Box color="text.secondary" className={clsx(classes.footer)}>
        <Typography variant="body1" className={clsx(classes.description)}>
          Cooperativa de Trabalho e Serviços do Rio Grande do Norte
        </Typography>
        <Typography variant="body2" className={clsx(classes.version)}>
          Versão {version}
        </Typography>
        <Link
          href="https://www.interativadigital.com.br/"
          title="Interativa Digital - Desde 1998 desenvolvendo os melhores projetos para Internet."
          target="_blank"
        >
          <img src={assinaturaImg} alt="Interativa" style={{ width: '80px' }} />
        </Link>
      </Box>
    </Box>
  );
};

export default Authentication;
