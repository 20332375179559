import { colors, makeStyles, Tabs, Theme, withStyles } from '@material-ui/core';

export const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginBottom: theme.spacing(3),
  },
}))(Tabs);

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 200,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  marginTopButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    boxShadow: 'none',
    height: 48,
  },
  buttonGrey: {
    color: colors.grey[500],
  },
  box: {
    borderRadius: '4px',
    padding: theme.spacing(1),
    color: colors.grey[700],
  },
  chipContent: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  chipGrey: {
    color: colors.grey[500],
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  fontW: {
    fontWeight: 600,
  },
  mediaFlexDirection: {
    paddingTop: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));
