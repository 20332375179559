import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '../Hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  template: React.ComponentType;
  isNotFound?: boolean;
}

type Location = {
  hash: string;
  pathname: string;
  search: string;
  state: {
    from: {
      hash: string;
      pathname: string;
      search: string;
    };
  };
};

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  template: Template,
  isNotFound = false,
  ...rest
}) => {
  const { user } = useAuth();
  const getLocation = useLocation<Location>();
  // console.log(getLocation);
  // @ts-ignore
  const { from } = getLocation?.state || { from: { pathname: '/dashboard' } };

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user || isNotFound ? (
          <Template>
            <Component />
          </Template>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : from.pathname,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
